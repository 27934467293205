<template>
  <div class="side-panel-title" @click="editable && $refs.editable && $refs.editable.focus()" :class="classes">
    <transition name="slide-down" appear>
      <div class="rte-suggestion" v-if="inFocus && suggestion" @click="$emit('set-suggestion')">
        <slot name="suggestions"></slot>
        <svg width="24" viewBox="0 0 24 24" class="icon-regular"><use xlink:href="#icon-arrow-left-top"></use></svg>
      </div>
    </transition>
    <div class="inner">
      <span class="txt-title-small side-panel-title-placeholder" v-if="(!title || title === '') && editable">{{ placeholder }}</span>
      <h3
        :key="resetKey"
        class="txt-title-small mb-0 txt-bold"
        :class="{'in-focus':inFocus, 'with-extension':extension}"
        ref="editable"
        :contenteditable="editable"
        @input="onInput"
        @focus="setFocus"
        @blur="onBlur"
        @keydown.enter="onEnter"
        spellcheck="false">
        <span>{{title}}</span>
      </h3>
      <transition name="fade" mode="out-in">
        <template v-if="inFocus">
          <div class="side-panel-title-actions" v-if="showActions">
            <slot name="actions"></slot>
            <b-button variant="icon" size="sm" @click.stop="reset">
              <svg width="24" viewBox="0 0 24 24" class="icon-sm"><use xlink:href="#icon-cross"></use></svg>
            </b-button>
            <b-button :disabled="error" variant="icon" size="sm ml-1" @click.stop="onBlur">
              <svg width="24" viewBox="0 0 24 24" class="icon-sm"><use xlink:href="#icon-tick"></use></svg>
            </b-button>
          </div>
        </template>
        <div v-else-if="buttons" class="side-panel-title-buttons ml-auto">
         <slot></slot>
        </div>
      </transition>
    </div>
    <p class="txt-body mt-2" v-if="error && errorMessage">{{ errorMessage }}</p>
  </div>
</template>
<script>
  import debounce from 'lodash/debounce'

  export default {
    name: 'SidePanelTitle',
    data() {
      return {
        title: this.value,
        initial: this.value,
        inFocus: false,
        resetKey: 0
      }
    },
    props: {
      //allow suggestion slot
      suggestion: {
        type: [Boolean, String],
        default: false,
      },
      value: String,
      editable: Boolean,
      error: Boolean,
      errorMessage: {},
      showActions: Boolean,
      buttons: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: 'Enter title...'
      },
      extension: {
        type: String,
        default: null
      }
    },
    computed: {
      classes() {
        return {
          'is-invalid': this.error,
        }
      },
    },
    methods: {
      debouncer: debounce((cb, args) => cb(args), 500),
      onBlur(e) {
        this.debouncer(() => {
          setTimeout(() => {
            this.inFocus = false
          }, 200)
          if (this.$refs.editable) this.$refs.editable.blur()
          this.$emit('blur', this.value);
          this.title = this.value;
        })
      },
      onInput(e) {
        this.$emit('input', e.target.innerText);
      },
      onEnter(e) {
        if (e.which === 13) e.preventDefault()
        this.onBlur()
      },
      reset() {
        this.title = this.initial;
        this.$emit('input', this.initial);
        this.resetKey++;
        this.$nextTick(() => {
          this.$refs.editable.blur();
          this.$emit('reset');
        })
      },
      focusTitle() {
        if (this.$refs.editable) this.$refs.editable.focus()
      },
      setFocus() {
        this.$emit('focus')
        this.inFocus = true
      },
      setContent(content) {
        this.editor.commands.setContent(typeof content === 'string' ? JSON.parse(content) : content);
        this.$emit('input', typeof content === 'string' ? content : JSON.stringify(content));
      }
    },
    mounted() {
      if (this.extension) {
        const style = document.createElement('style');
        const customContent = `.${this.extension}`;
        style.textContent = `
          .with-extension::after {
            content: '${customContent}';
            font-weight: 400;
            color: #BBBBBB;
          }
        `;
        document.head.appendChild(style);
      }
    }
  }
</script>

